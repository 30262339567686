import "./App.css";
import { useState } from "react";
import CirclePacking from "./components/CirclePacking";
import { ImageCrop } from "./components/ImageCrop";
import { useEffect } from "react";
import { callServer } from "./services/callServer";
import { getCroppedImg } from "./components/getCroppedImage";
import { blobToBase64 } from "./components/blobToBase64";
import { AutoComplete, Card, Text, Spacer, Button } from '@geist-ui/core'
// import { jsonTree } from "./jsonTree";
// import { jsonListAll } from "./jsonList";
import { HelpAndContact } from "./components/helpAndContact";

function printNode(node) {
  // Print everything except the children property
  return node
    ? JSON.stringify(
      node,
      (key, value) => (key === "children" ? undefined : value),
      2
    )
    : "";
}

function App() {
  const [hoveredNode, setHoveredNode] = useState(null);
  const [clickedNode, setClickedNode] = useState(null);
  const [focusItemId, setFocusItemId] = useState(null);
  const [tree, setTree] = useState(null);
  const [list, setList] = useState([]);
  const [listAll, setListAll] = useState([]);

  const onClickFocus = () => {
    setFocusItemId(clickedNode.name);
  }

  const onClickFinish = async (croppedAreaPixels, imageSrc, newItemName) => {
    const parentId = clickedNode?.id;
    let blob, imageBase64;

    if (imageSrc && croppedAreaPixels) {
      blob = await getCroppedImg(imageSrc, croppedAreaPixels);
      imageBase64 = await blobToBase64(blob);
    }

    const json = await callServer({ functionName: 'createNewItem', body: { imageBase64, parentId, itemName: newItemName } });
  }

  const searchHandler = (currentValue) => {
    if (!currentValue) return setList([])
    const relatedOptions = listAll.filter(item => item.value.toLowerCase().includes(currentValue.toLowerCase()))
    setList(relatedOptions)
  }

  const onSelect = (name) => {
    const item = listAll.filter(i => i.name == name)[0];
    setClickedNode(item);
  }

  useEffect(() => {

    const fetchData = async () => {

      //for debug:
      //let tree = jsonTree;
      //let listAll = jsonListAll;

      console.log(123);
      let { tree, listAll } = await callServer({ functionName: 'getChartData' });
      console.log(tree);
      setTree(tree[0]);
      setListAll(listAll);
    }

    fetchData()
  }, []);

  return (
    <main>
      {tree ? <CirclePacking
        data={tree}
        setHoveredNode={setHoveredNode}
        setClickedNode={setClickedNode}
        focusItemId={focusItemId}
        setFocusItemId={setFocusItemId}
      /> : null}
      <div>
        <AutoComplete clearable placeholder="Search something" options={list} onSearch={searchHandler} onSelect={onSelect} />
        <Spacer h={1} />
        <Card style={{ paddingRight: 30 }}>
          <div style={{ display: 'flex' }}>
            <div>
              <Text h4 my={0}>{clickedNode?.name || 'Pick an item...'}</Text>
              <Spacer h={1} />
              <Button onClick={onClickFocus}>Focus</Button>
            </div>
            {clickedNode && clickedNode.image_url ? <input type='image' style={{ borderWidth: 1, borderColor: 'black', borderStyle: 'solid', borderRadius: 50, marginLeft: 30 }} width="70" height="70" src={clickedNode.image_url} /> : null}
          </div>
        </Card>
        <div class='hovername'>{hoveredNode?.name || 'Hover on an item...'}</div>
        <ImageCrop clickedNode={clickedNode} onClickFinish={onClickFinish} />
        <HelpAndContact />
      </div>
    </main>
  );
}

export default App;
