import { Spacer } from '@geist-ui/core';
import { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { handleOnFileChange } from './handleOnFileChange';

export function ImageCrop({ onClickFinish }) {
    const [crop, setCrop] = useState({ x: 100, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [imageSrc, setImageSrc] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [newItemName, setNewItemName] = useState('');
    const [password, setPassword] = useState('');

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, []);

    const onFileChange = async (e) => {
        const imageDataUrl = await handleOnFileChange(e);
        setImageSrc(imageDataUrl)
    }

    const onNewItemTextChange = (event) => {
        setNewItemName(event.target.value);
    }

    const onClickFinishLocal = () => {
        onClickFinish(croppedAreaPixels, imageSrc, newItemName)
    }

    return (
        <div>
            <div style={{ padding: 10, borderColor: 'black', borderWidth: 1, borderStyle: 'solid', borderRadius: 10 }} >
                <div>Upload New Item:</div>
                <Spacer h={1} />
                <input type="file" onChange={onFileChange} accept="image/*" />
                <Spacer h={1} />
                <input placeholder='item name' value={newItemName} onChange={onNewItemTextChange} />
                <Spacer h={1} />
                <div>
                    <input placeholder='password' value={password} onChange={setPassword} />
                </div>
                <Spacer h={1} />
                <div>
                    <input type='button' value='finish' onClick={onClickFinishLocal} />
                </div>
            </div>

            {imageSrc ? <>
                <Spacer h={1} />
                <div style={{ position: 'relative', width: 300, height: 300 }} >
                    <Cropper
                        restrictPosition={false}
                        cropShape='round'
                        minZoom={0.1}
                        showGrid={false}
                        cropSize={{ width: 300, height: 300 }}
                        image={imageSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
            </> : null}
        </div>
    )
}