export async function callServer({ functionName, body }) {
    const data = await fetch("https://www.alltechchart.com/api/" + functionName, {
        body: body ? JSON.stringify(body) : '{}',
        headers: {
            "Content-Type": "application/json"
        },
        method: "POST",
        mode: 'no-cors'
    });
    const json = await data.json();
    return json;
}