import { useEffect, useMemo, useRef } from "react";
import {
  hierarchy,
  interpolateHcl,
  pack,
  scaleLinear,
  select,
  zoom,
  zoomIdentity,
} from "d3";
import useSize from "../hooks/useSize";
import "./CirclePacking.css";

export default function CirclePacking({
  data,
  setHoveredNode,
  setClickedNode,
  focusItemId,
  setFocusItemId,
}) {
  const containerRef = useRef(null);
  const size = useSize(containerRef);
  const height = size?.height || 400;
  const width = height;

  const copiedData = useMemo(() => {
    return JSON.parse(JSON.stringify(data));
  }, [data]);

  const root = useMemo(() => {
    const root = pack().size([width, height]).padding(5)(
      hierarchy(copiedData)
        .count()
        .sort((a, b) => b.value - a.value)
    );

    /**
     * Manually add placeholder fields.
     * - id
     * - image
     * This code block should be deleted for the actual data
     */
    let id = 0;
    root.each((d) => {
      //d.id = id++;
      /**
       * Add placeholder image
       */
      //d.data.image_url = "https://devfomo.s3.eu-west-1.amazonaws.com/planet_sclae_big.png";
    });

    return root;
  }, [copiedData, height, width]);

  const zoomRef = useRef(null);

  useEffect(() => {
    const color = scaleLinear()
      .domain([-1, 5])
      .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
      .interpolate(interpolateHcl);

    zoomRef.current = zoom()
      .extent([
        [0, 0],
        [width, height],
      ])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .scaleExtent([1, 64])
      .on("zoom", zoomed);

    const container = containerRef.current;
    const svg = select(container)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .style("display", "block")
      //.style("background", color(-1))
      .style("background", 'white')
      .call(zoomRef.current)
      .on("mouseleave", left);

    const g = svg.append("g");

    const node = g
      .selectAll(".node")
      .data(root.descendants())
      .join((enter) =>
        enter
          .append("g")
          .attr("class", (d) =>
            d.parent
              ? d.children
                ? "node"
                : "node node--leaf"
              : "node node--root"
          )
          .classed("node--group", (d) => !!d.data.groupName)
          //.attr("fill", (d) => (d.children ? color(d.depth) : null))
          .attr("fill", (d) => (d.children ? 'white' : null))
          .attr("transform", (d) => `translate(${d.x},${d.y})`)
          .call((g) =>
            g
              .filter((d) => !d.children)
              .append("clipPath")
              .attr("id", (d) => `clip-${d.id}`)
              .append("circle")
              .attr("class", "node__clip")
              .attr("r", (d) => d.r)
          )
          .call((g) =>
            g
              .filter((d) => !d.children)
              .append("image")
              .attr("class", "node__image")
              .attr("clip-path", (d) => `url(#clip-${d.id})`)
              .attr("href", (d) => d.data.image_url)
              .attr("x", (d) => -d.r)
              .attr("y", (d) => -d.r)
              .attr("width", (d) => d.r * 2)
              .attr("height", (d) => d.r * 2)
          )
          .call((g) =>
            g
              .append("circle")
              .attr("class", "node__circle")
              .attr("r", (d) => d.r)
          )
          .on("mouseenter", entered)
          .on("mouseleave", left)
          .on("click", clicked)
      );

    const nodeCircle = node.select(".node__circle");
    const nodeClip = node.select(".node__clip");
    const nodeImage = node.select(".node__image");

    function zoomed({ transform }) {
      node.attr(
        "transform",
        (d) => `translate(${transform.applyX(d.x)},${transform.applyY(d.y)})`
      );
      nodeCircle.attr("r", (d) => d.r * transform.k);
      nodeClip.attr("r", (d) => d.r * transform.k);
      nodeImage
        .attr("x", (d) => -d.r * transform.k)
        .attr("y", (d) => -d.r * transform.k)
        .attr("width", (d) => d.r * transform.k * 2)
        .attr("height", (d) => d.r * transform.k * 2);
    }

    function entered(event, d) {
      setHoveredNode({ ...d.data });
    }

    function left(event, d) {
      if (d === undefined || d.depth === 0) {
        setHoveredNode(null);
      }
    }

    function clicked(event, d) {
      const node = { ...d.data };
      setClickedNode(node);
    }

    return () => {
      select(container).selectAll("*").remove();
    };
  }, [height, root, setClickedNode, setHoveredNode, width]);

  useEffect(() => {
    if (!focusItemId) return;
    const container = containerRef.current;
    const svg = select(container).select("svg");
    const node = svg.selectAll(".node");
    const found = node.filter((d) => d.data.name === focusItemId);
    if (found.size() === 0) return;
    const d = found.datum();
    const transform = zoomIdentity
      .translate(width / 2, height / 2)
      .scale((width / (d.r * 2)) * 0.8)
      .translate(-d.x, -d.y);
    svg
      .transition()
      .duration(750)
      .call(zoomRef.current.transform, transform)
      .end()
      .then(() => {
        setFocusItemId(null);
      });
  }, [focusItemId, height, setFocusItemId, width]);

  return <div ref={containerRef} className="circle-packing"></div>;
}
